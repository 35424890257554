export default {
  name: "Header",

  data() {
    return {
      active: "/"
    };
  },

  methods: {
    clickCgy() {
      window.open('https://www.changguanyi.com');
    }

  }
};