// router/index

import {createRouter, createWebHistory} from 'vue-router'

const Home = () => import('../views/home/Home.vue')
const About = () => import('../views/about/About.vue')

const routes = [
    { path: '/', component: Home },
    { path: '/about', component: About },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
